import * as React from "react";
import Layout from "../_components/Layout/Layout";
import MainTitle from "../_components/MainTitle/MainTitle";
import PolicyDescription from "../_components/PolicyDescription/PolicyDescription";

const Privacy = () => {
    return (
        <Layout
            title="Privacy Policy"
            hideFooterNavigation={false}
            hideCookies={true}
        >
            <MainTitle>Privacy Policy</MainTitle>

            <MainTitle secondary>Introduction</MainTitle>

            <PolicyDescription>
                Fabacus collects and processes on behalf of International Management Group (UK) Limited (collectively “IMG”), your personal data to allow your participation in the rewards giveaway and other programs, claim a reward and receive information from IMG regarding Event offers, products and services. The processing of your personal data is necessary to perform the contract you have entered into with IMG by accepting the Terms and Conditions of the Giveaway Program. Your personal data will be accessible by Fabacus, IMG and IMG affiliates, and will be stored until the end of the registration of the Giveaway Program. You can exercise your rights regarding your data and request a copy, the deletion or rectification of your data, object to or request the restriction of your processing, and/or receive your information in portable form by contacting Fabacus’ data protection officers here: info@Fabacus.com. Your request will be handled in cooperation with IMG ’s data protection officers. After contacting protection officers, if you are not satisfied with the way your request was handled, you may address a complaint to the regulatory authority of your country.
            </PolicyDescription>

            <MainTitle secondary>Main Privacy Policy:</MainTitle>

            <PolicyDescription>
                This is the Privacy Policy (the ‘Policy’) of Fabacus Services Limited, company number 10123148 of 12 Soho Square, London, England, W1D 3QF (hereafter ‘Fabacus’) acting on behalf International Management Group (UK) Limited (company registered number 00946580) with registered offices at Building Six, Chiswick Park, 566 Chiswick High Road, London W4 5HR (and referred to in these Terms of Use as “IMG”) and with Fabacus for purposes of this Policy, ‘we’ or ‘us’). We take your privacy seriously. This Policy describes the ways in which we collect and use information about you when participate in a program or promotion or use any of our websites and mobile applications that contain a link to this Privacy Policy (collectively, the “Site”). By using the Site or otherwise providing your Personal Information, you agree to this Privacy Policy.
            </PolicyDescription>

            <MainTitle secondary>PERSONAL INFORMATION</MainTitle>

            <PolicyDescription>
                “Personal Information” generally means any information that identifies you as an individual, and any other information associated with it based on the categories of information we collect from various sources such as:
                <br/>
                • Profile information and other voluntarily-provided content, which could include: user name and password; contact information; demographic information; and date of birth.
                <br/>
                • Content you submit on the Site such as comments, videos and photos. If you use the Site via a social media site, we may collect your social media identifier.
                <br/>
                • Payment information required to process any transaction.
                <br/>
                • Passively-collected information via our web servers and by third party analytics tools we use when you use the Site such as the web request, hardware and software Internet Protocol (“IP”) address, device and mobile ad identifiers, physical device location and device ID and type, browser information, interaction with the Site, pages viewed, app usage, time zones, and other such information; information on device sensors such as accelerometers, gyroscopes, and compasses; and certain cookies, pixel tags and other technologies to help us understand how you use the Site and allow us to provide a personalized experience. (See the Cookie Policy). You may opt out of this passive information collection via your device settings.
                <br/>
                We may anonymise information and aggregate and use it for marketing and other activities. We may use and share anonymised data (meaning information that, by itself, does not identify who you are such as device information, general demographics, general behavioural personal information, geolocation in de-identified form), as well as personal information in an aggregated, hashed, non-human readable form, under any of the above circumstances. We may combine this information with additional anonymised data or personal information in hashed, non-human readable form collected from other sources. Anonymised information is outside the scope of this Privacy Policy.
            </PolicyDescription>

            <MainTitle secondary>USE OF PERSONAL INFORMATION</MainTitle>

            <PolicyDescription>
                We may lawfully use Personal Information as follows: to respond to your inquiries and requests; to communicate with you about and to process payment transactions, campaigns, programs, contests, sweepstakes, and rewards and to fulfill rewards; to inform you about brands, products, promotions, events or other promotional purposes; to send you advertising/promotional material; to improve or personalize your experience with the Site; to analyze and improve our services; to allow you to participate in polls, sweepstakes, promotions, and contests; to permit you to participate in social media sharing; to comply with applicable laws and legal processes such as a court order, subpoena or search warrant, government / law enforcement investigation or other legal requirements; to respond to public and governmental authority requests; to detect, prevent, or investigate potential security incidents or fraud; to facilitate Site functionality; and to enforce terms and conditions and protect privacy, safety, property, or security rights.
                <br />We may also seek your consent to use your personal information in additional ways. You may withdraw your consent at any time by notifying us on glidebattersea@help.xela.co.
            </PolicyDescription>

            <MainTitle secondary>DISCLOSURE OF PERSONAL INFORMATION</MainTitle>

            <PolicyDescription>
                As legally permitted, all of your Personal Information may be disclosed to the following: third-party service providers who provide services such as website hosting, data analysis, payment processing, order fulfilment, infrastructure provision, IT services, customer service, email delivery services, credit card processing, auditing services and other services; in connection with legal obligations; and if Fabacus or IMG is involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control.
                <br/>
                As legally permitted, your name, contact information, and other information you make public may be disclosed to the following: third-party strategic and promotional partners including third parties in connection with certain promotions or sweepstakes.
                <br/>
                As legally permitted, passively-collected information from your use of the Site may be disclosed to the following: our online and email advertisers or other third-party vendors we use who may provide cookies, pixel tags, web beacons, clear GIFs or other similar technologies for use on our Site or other websites to manage and improve our online and email advertising campaigns.
            </PolicyDescription>

            <MainTitle secondary>THIRD PARTY SITES AND INTEGRATIONS</MainTitle>

            <PolicyDescription>
                The Site may integrate third party video streaming services offered by certain partners. We do not control the content or privacy practices of those third party services and their functionality may be subject to those parties’ privacy statements. For your convenience only, the Site may link to third party websites, mobile applications, and other online services or platforms. We do not control such third parties and is not responsible for the content, availability, or security of third parties.
            </PolicyDescription>

            <MainTitle secondary>SECURITY AND EXTRA-TERRITORIAL TRANSMITTAL</MainTitle>

            <PolicyDescription>
                If Fabacus sends your Personal Information outside of your country, we have in place adequate safeguards to do so. This includes EU standard contract clauses approved by the European Commission or other suitable safeguard to permit personal information transfers from the European Economic Area (“EEA”) to other countries. Fabacus has implemented, and will maintain current, reasonable physical, technical, and organizational security measures to protect your personal information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. Unfortunately, the transmission of information via the internet is not completely secure. Although we have security measures in place to protect your personal information, we cannot guarantee the security of your data transmitted to our Site; any transmission is at your own risk.
            </PolicyDescription>

            <MainTitle secondary>YOUR RIGHTS</MainTitle>

            <PolicyDescription>
                In certain circumstances, including, if you are a California resident, you may exercise the rights available to you under applicable data protection laws as follows: If you wish to access, correct, update or request deletion of your personal information. You can object to processing of your personal information, ask us to restrict processing of your personal information or request portability of your personal information. If we have collected and process your personal information with your consent, then you can withdraw your consent at any time. This may mean your access to certain services is restricted or denied as a result. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent. You may have the right to complain to a data protection authority about our collection and use of your personal information. For more information, please contact your local data protection authority (in California this is the California Privacy Protection Agency).
                <br/>
                We respond to all requests we receive from users in accordance with applicable data protection laws. We may ask you to provide proof of identity before we can answer the above requests. In some cases, we may reject requests for certain reasons (for example, if the request is unlawful or if it may infringe on trade secrets or intellectual property or the privacy of another user). At this time, we do not respond to browser ‘Do Not Track’ signals.
            </PolicyDescription>

            <MainTitle secondary>RETENTION PERIOD</MainTitle>

            <PolicyDescription>
                Your Personal Information will be stored in accordance with applicable laws and kept for as long as needed to carry out the purposes described in this Policy or as otherwise required by applicable law or as permitted for auditing or compliance purposes.
            </PolicyDescription>

            <MainTitle secondary>PRIVACY POLICY UPDATES</MainTitle>

            <PolicyDescription>
                You should visit this Policy each time you visit the Site to review the current terms which you must abide by.
            </PolicyDescription>

            <MainTitle secondary>CONTACTING US</MainTitle>

            <PolicyDescription>
                We welcome your questions, comments, and concerns about privacy. If you have any questions about this Privacy Policy or our data practices, please contact us as follows: by e-mail at info@Fabacus.com or write to us at Data Protection Query, Fabacus Services Limited, 12 Soho Square, London, England, W1D 3QF.
            </PolicyDescription>
        </Layout>
    );
};

export default Privacy;
